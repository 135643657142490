export default {
  methods: {
    getMoneyFormat(amount) {
      return new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD',
      }).format(amount)
    },
  },
}
