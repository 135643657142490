<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold">
        Listings ({{ listings.length }})
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-row v-show="showSearch" align="center" style="max-width: 500px">
        <v-col class="col-5">
          <v-select
            class="ml-10 text-capitalize"
            hide-details
            dense
            :items="['title', 'category']"
            v-model="filter.field"
            style="max-width: 300px"
            outlined
          ></v-select>
        </v-col>

        <v-col>
          <v-text-field
            class="search mr-5"
            ref="searchField"
            flat
            hide-details
            solo
            rounded
            clearable
            background-color="grey lighten-3"
            label="Search label"
            v-model="filter.search"
            @input="search"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-skeleton-loader
        type="actions"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <template>
        <v-select
          class="ml-10"
          hide-details
          dense
          item-text="label"
          item-value="field"
          v-model="sort"
          :items="sortFields"
          style="max-width: 200px"
        />
        <v-btn
          depressed
          class="ml-2"
          :color="sortOrder ? 'primary' : ''"
          @click="
            sortOrder = true
            filterListing()
          "
        >
          <v-icon>{{ icons.arrowUp }}</v-icon>
        </v-btn>
        <v-btn
          depressed
          class="ml-2"
          :color="!sortOrder ? 'primary' : ''"
          @click="
            sortOrder = false
            filterListing()
          "
        >
          <v-icon>{{ icons.arrowDown }}</v-icon>
        </v-btn>

        <v-btn class="ml-5" icon @click="showSearchField">
          <img src="@/assets/icons/search.svg" alt height="17.7px" />
        </v-btn>
      </template>
    </v-app-bar>

    <div
      class="mt-12 mx-4"
      id="listings-list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <v-simple-table>
        <thead>
          <tr>
            <th width="15%" colspan="2" class="text-left pa-0">Title</th>
            <th width="10%" class="text-left">Rating</th>
            <th width="20%" colspan="2" class="text-left pa-0">Description</th>
            <th width="20%" class="text-left">Owner</th>
            <th width="10%" class="text-left">Price</th>
            <th width="10%" class="text-left">Bond</th>
            <th width="10%" class="text-left">Status</th>
            <th width="15%" class="text-left">Date Added</th>
            <th width="5%" class="text-left">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="listing in listings" :key="listing.id">
            <td colspan="2" class="text-capitalize">
              {{ listing.title }}
            </td>
            <td>
              <v-rating
                half-increments
                v-model="listing.rating"
                background-color="orange lighten-3"
                color="orange"
                readonly
                size="40"
              />
            </td>
            <td colspan="2">
              {{ listing.description }}
            </td>
            <td class="text-capitalize">{{ listing.author.full_name }}</td>
            <td>${{ listing.price }}</td>
            <td>{{ getMoneyFormat(listing.bond) }}</td>
            <td>
              <p v-if="!listing.disabled_at" class="green--text">Active</p>
              <p v-else class="red--text">Disabled</p>
            </td>
            <td>{{ listing.addedDate }}</td>
            <td
              class="clickable"
              @click="
                $router.push({
                  name: 'listing.edit',
                  params: { id: listing.id },
                })
              "
            >
              <v-icon>{{ icons.pencil }}</v-icon>
            </td>
          </tr>

          <template v-if="loading">
            <tr v-for="index in 10" :key="index + '-skeleton'">
              <td class="py-3 pl-0" width="5%">
                <v-skeleton-loader
                  class="avatar-loading--36"
                  type="avatar"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
            </tr>
          </template>
        </tbody>
      </v-simple-table>

      <div
        class="d-flex justify-center mt-3"
        v-if="!(listings.length > 0) && !loading"
      >
        <p>No match found.</p>
      </div>
    </div>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color" right>
      {{ snackbar.message }}
      <v-btn color="white" text @click="snackbar.show = false">Ok</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import {
  mdiDotsVertical,
  mdiUnfoldMoreHorizontal,
  mdiMagnify,
  mdiAccountCircleOutline,
  mdiChevronRight,
  mdiFileExcel,
  mdiArrowUp,
  mdiArrowDown,
  mdiCheck,
  mdiPencil,
} from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'
import Currency from '@/utils/mixins/Currency'

export default {
  name: 'ListingsPage',

  mixins: [ControlsMixin, Currency],

  directives: {
    infiniteScroll,
  },

  components: {
    AppBarNavIcon,
  },

  data() {
    return {
      loading: false,
      showSearch: false,
      showProfile: false,
      selectedProfile: null,
      filter: {
        search: '',
        field: 'title',
      },
      icons: {
        settings: mdiDotsVertical,
        sort: mdiUnfoldMoreHorizontal,
        search: mdiMagnify,
        view: mdiAccountCircleOutline,
        arrow: mdiChevronRight,
        export: mdiFileExcel,
        arrowUp: mdiArrowUp,
        arrowDown: mdiArrowDown,
        check: mdiCheck,
        pencil: mdiPencil,
      },
      snackbar: {
        show: false,
        message: null,
        color: null,
      },
      defaultFilter: 'created_at',
      sortOrder: true,
      sort: 'created_at',
      sortFields: [
        {
          label: 'Title',
          field: 'title',
        },
        {
          label: 'Price',
          field: 'price',
        },
        {
          label: 'Date added',
          field: 'created_at',
        },
      ],
    }
  },

  created() {
    this.clearListings()
    this.fetchListings(1)
  },

  computed: {
    ...mapState({
      listings: (state) => state.listing.list,
      listMeta: (state) => state.listing.listMeta,
    }),
    filteredCategories() {
      if (!this.filter.search || this.filter.search.trim() === '') {
        return this.categories
      }

      return this.categories.filter((c) =>
        c.label.toLowerCase().includes(this.filter.search.toLowerCase())
      )
    },
  },

  methods: {
    ...mapActions({
      getListings: 'listing/getListings',
    }),

    ...mapMutations({
      clearListings: 'listing/clearListings',
    }),

    pageChanged(page) {
      this.fetchListings(page)
    },

    filterListing() {
      this.clearListings()
      this.fetchListings()
    },

    search: debounce(function () {
      this.clearListings()
      this.fetchListings()
    }, 600),

    async fetchListings(page = 1) {
      if (this.loading) return
      let params = {
        page,
        sort: this.sortOrder ? `${this.sort}` : `-${this.sort}`,
      }

      if (this.filter.search !== '') {
        params.filter = {
          search: this.filter.search,
          field: this.filter.field,
        }
      }

      params.include = ['author', 'category']

      this.loading = true
      await this.getListings(params)
      this.loading = false
    },

    showProfileDialog(profile) {
      this.selectedProfile = profile
      this.showProfile = true
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.color = color
      this.snackbar.show = true
    },
    async showSearchField() {
      this.showSearch = !this.showSearch
      await this.$nextTick()
      this.$refs.searchField.$refs.input.focus()
    },

    resetSearch() {
      this.filter.search = ''
      this.search()
    },

    loadMore() {
      if (this.listMeta.current_page < this.listMeta.last_page) {
        this.fetchListings(this.listMeta.current_page + 1)
      }
    },
  },

  watch: {
    'filter.field': function (newValue) {
      if (newValue && this.filter.search !== '') {
        this.search()
      }
    },
    showSearch(newValue) {
      if (newValue === false && this.filter.search !== '') {
        this.resetSearch()
      }
    },

    sort: function (newValue) {
      this.filterListing()
    },
  },
}
</script>
