<template>
  <div class="py-8" v-if="listing">
    <!-- add v-if="form" -->
    <div class="ma-4" v-if="form">
      <div class="display-2 font-weight-bold my-6 text-capitalize">
        {{ displayName }}
      </div>

      <v-alert
        dense
        outlined
        type="warning"
        color="orange darken-3"
        max-width="400px"
        v-if="listing && !!listing.disabled_at"
      >
        This listing is <strong>disabled</strong>. <br />
        Reason: {{ listing.disable_reason }}
      </v-alert>

      <div class="d-flex my-6">
        <v-form ref="form" class="listing-form">
          <label class="text-field-label">Title</label>
          <v-text-field
            flat
            solo
            required
            class="mt-2"
            v-model="form.title"
            name="title"
            :error-messages="form.$getError('title')"
            :loading="loading"
          ></v-text-field>

          <!-- Text Area -->
          <label class="text-field-label">Description</label>
          <v-textarea
            filled
            name="description"
            v-model="form.description"
            :error-messages="form.$getError('description')"
            :loading="loading"
          ></v-textarea>

          <label class="text-field-label">Sub-Category</label>
          <v-select
            filled
            :items="subCategories"
            item-text="label"
            v-model="form.category_id"
            item-value="id"
            name="category_id"
            :error-messages="form.$getError('category_id')"
          />

          <!-- calendar availabilities here -->
          <label class="text-field-label">Unavailabilities</label>
          <div>
            <v-date-picker
              multiple
              no-title
              v-model="form.unavailabilities"
              name="unavailabilities[]"
            ></v-date-picker>
          </div>

          <!-- gallery here -->
          <label class="text-field-label">Photos</label>
          <v-file-input
            multiple
            label="Upload photos"
            v-model="newPhotos"
            @change="addPhotos"
            v-on:click:clear="false && clearFormPhotos"
            :error-messages="form.$getError('photos')"
            accept="image/*"
            class="mb-2"
          ></v-file-input>
          <gallery :images="form.photos" @remove="deletePhoto" />
          <!-- end of gallery -->

          <label class="text-field-label">Location</label>
          <location-field
            :id="form.location_places_id"
            :text="locationText"
            :error-messages="form.$getError('location_places_id')"
            @change="setLocation"
          />

          <label class="text-field-label">Price</label>
          <v-text-field
            flat
            solo
            required
            class="mt-2"
            v-model="form.price"
            :error-messages="form.$getError('price')"
            name="price"
            :loading="loading"
          ></v-text-field>

          <label class="text-field-label">Bond</label>
          <v-text-field
            flat
            solo
            required
            class="mt-2"
            v-model="form.bond"
            :error-messages="form.$getError('bond')"
            name="bond"
            :loading="loading"
          ></v-text-field>

          <label class="text-field-label">Condition</label>
          <v-select
            filled
            :items="conditions"
            item-text="text"
            item-value="value"
            v-model="form.condition"
            :error-messages="form.$getError('condition')"
            name="condition"
          />

          <label class="text-field-label">Special Instructions</label>
          <v-textarea
            filled
            name="special_instructions"
            v-model="form.special_instructions"
            :error-messages="form.$getError('special_instructions')"
            :loading="loading"
          ></v-textarea>

          <!--          <v-checkbox-->
          <!--            :input-value="listing && listing.disabled_at"-->
          <!--            label="Disable Listing"-->
          <!--            color="orange darken-3"-->
          <!--            class="mt-0"-->
          <!--            @mousedown="showDisableDiaglog = true"-->
          <!--          ></v-checkbox>-->

          <div>
            <div class="mt-2">
              <v-btn
                color="error"
                outlined
                class="mr-4 px-6"
                height="40px"
                width="100%"
                @click="showDeleteDialog = true"
                >Delete</v-btn
              >
            </div>

            <v-btn
              color="primary"
              class="mt-3"
              height="40px"
              width="100%"
              @click="updateListing"
              :loading="form.$busy"
            >
              <v-icon>{{ icons.save }}</v-icon>
              Save Changes
            </v-btn>
          </div>
        </v-form>
      </div>

      <v-overlay absolute :value="loading" opacity="0.2">
        <v-progress-circular
          indeterminate
          color="primary"
          size="64"
        ></v-progress-circular>
      </v-overlay>
      <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
        {{ snackbar.message }}
        <v-btn text @click="snackbar.show = false">OK</v-btn>
      </v-snackbar>
    </div>

    <!-- Disable modal -->
    <confirm-modal
      v-model="showDisableDiaglog"
      title="Disable Listings"
      :message="disableMessage"
      @cancel="showDisableDiaglog = false"
      @confirm="disable"
      confirm-color="error"
    >
      <template v-slot:content v-if="!listing.disabled_at">
        <v-text-field
          label="Input reason (Optional)"
          v-model="disableReason"
        ></v-text-field>
      </template>
    </confirm-modal>

    <confirm-modal
      v-model="showDeleteDialog"
      title="Delete Listing"
      message="Are you sure you want to delete this listing?"
      confirm-color="error"
      @cancel="showDeleteDialog = false"
      @confirm="deleteListing"
    />
  </div>
</template>

<script>
import { mdiContentSave } from '@mdi/js'
import { mapActions, mapState } from 'vuex'
import Form from '@/utils/form'
import { pick, get, flatMapDeep, sortBy } from 'lodash'
import LocationField from './LocationField'
import ConfirmModal from '@/components/modals/ConfirmModal'
import Gallery from './Gallery'
import Snackbar from '@/utils/mixins/Snackbar'

export default {
  name: 'ListingForm',
  mixins: [Snackbar],

  components: {
    LocationField,
    Gallery,
    ConfirmModal,
  },

  data() {
    return {
      form: null,
      loading: false,
      icons: {
        save: mdiContentSave,
      },
      conditions: [
        {
          text: 'Brand New',
          value: 'brand_new',
        },
        {
          text: 'Like New',
          value: 'like_new',
        },
        {
          text: 'Used Excellent',
          value: 'used_excellent',
        },
        {
          text: 'Used Good',
          value: 'used_good',
        },
      ],
      showDisableDiaglog: false,
      disableReason: '',
      showDeleteDialog: false,
      newPhotos: [],
      deletedPhotos: [],
    }
  },

  created() {
    this.fetchCategories()
    this.getListing()
  },

  computed: {
    ...mapState({
      listing: (state) => state.listing.listingDetails,
      categories: (state) => state.category.list,
    }),

    displayName() {
      return this.listing.title
    },

    locationText() {
      return this.listing.location ? this.listing.location.full_name : ''
    },

    disableMessage() {
      return `Are you sure you want to ${this.disableAction} this listing?`
    },

    disableAction() {
      return this.listing && this.listing.disabled_at ? 'enable' : 'disable'
    },

    subCategories() {
      let subCategories = flatMapDeep(this.categories, function (category) {
        return category.subcategories
      })

      return sortBy(subCategories, ['label'])
    },
  },

  methods: {
    ...mapActions({
      getCategories: 'category/getCategories',
      getListingDetails: 'listing/getListingDetails',
      updateListingDetails: 'listing/updateListing',
      disableListing: 'listing/disableListing',
    }),

    disable() {
      this.form.$busy = true
      let action = this.disableAction

      this.disableListing({
        id: this.listing.id,
        action,
        reason: this.disableReason,
      })
        .then(() => {
          this.form.$busy = false
          this.showDisableDiaglog = false
          this.showSnackbar(`Listing successfully ${action}!`, 'success')
        })
        .catch((err) => {
          this.form.$busy = false
          this.showDisableDiaglog = false
          if (err.response.status === 422) {
            this.showSnackbar(`Failed to ${action} listing!`, 'error')
          }
        })
    },

    async updateListing() {
      this.form.$busy = true
      this.form.$clearErrors()

      let formData = this.prepareData()

      this.updateListingDetails(formData)
        .then(() => {
          this.form.$busy = false

          this.showSnackbar('Listing details successfully updated!', 'success')
        })
        .catch((err) => {
          this.form.$busy = false
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
    },

    deleteListing() {
      this.form.$busy = true

      this.listing
        .delete()
        .then(() => {
          this.showMainSnackbar('Listing deleted successfully!', 'success')
          this.$router.replace('/listings')
        })
        .catch((e) => {
          this.showSnackbar(get(e, 'response.data.message'), 'error')
        })
        .finally(() => {
          this.form.$busy = false
          this.showDeleteDialog = false
        })
    },

    async getListing() {
      this.loading = true
      await this.getListingDetails(this.$route.params.id)
      this.initForm()
      this.loading = false
    },

    initForm() {
      this.form = new Form(
        pick(this.listing, [
          'id',
          'title',
          'description',
          'category_id',
          'location_places_id',
          'location',
          'price',
          'bond',
          'special_instructions',
          'condition',
          'unavailabilities',
          'photos',
        ])
      )

      this.form.unavailabilities = this.listing.unavailabilities.map(
        (item) => item.date
      )
    },

    async fetchCategories() {
      if (this.loading) return
      let params = {
        sort: 'label',
      }

      this.loading = true
      await this.getCategories(params)
      this.loading = false
    },

    setLocation(id) {
      this.form.location_places_id = id
    },

    addPhotos(photos) {
      this.form.photos = this.form.photos.concat(photos)

      this.newPhotos = []
    },

    deletePhoto(photo, ndx) {
      if (!(photo instanceof File)) {
        this.deletedPhotos.push({
          id: photo.id,
          delete: true,
        })
      }

      // listing must have at least 1 image
      // remove image from the gallery
      this.form.photos.splice(ndx, 1)
    },

    prepareData() {
      let formData = new FormData(this.$refs.form.$el)
      formData.id = this.form.id

      this.appendPhotosField(formData)

      // set unavailabilities
      this.form.unavailabilities.forEach((value) => {
        formData.append('unavailabilities[]', value)
      })

      return formData
    },

    appendPhotosField(formData) {
      // add photos to formdata
      let newPhotosCount = 0

      this.form.photos.concat(this.deletedPhotos)

      this.form.photos.forEach((photo, index) => {
        newPhotosCount++
        if (photo instanceof File) {
          formData.append(`photos[${index}]`, photo)
        }
      })

      // to be deleted photos
      this.deletedPhotos.forEach((photo, index) => {
        let photoIndex = newPhotosCount + index
        formData.append(`photos[${photoIndex}][id]`, photo.id)
        formData.append(`photos[${photoIndex}][delete]`, true)
      })
    },
  },

  watch: {
    $route() {
      this.getListing()
    },
  },
}
</script>

<style lang="scss" scoped>
.listing-form {
  width: 400px;

  @media (max-width: 420px) {
    width: 100%;
  }

  .v-text-field {
    max-width: 25rem;
  }
}
</style>
