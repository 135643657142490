<template>
  <div class="gallery px-0">
    <v-row>
      <v-col
        v-for="(image, index) in images"
        :key="index"
        class="d-flex child-flex gallery--photo"
        cols="3"
      >
        <v-img
          :src="getImageUrl(image)"
          :lazy-src="getImageUrl(image)"
          aspect-ratio="1"
          contain
          class="grey lighten-4"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
        <v-btn
          class="gallery--photo__delete-btn w-10"
          color="error"
          fab
          x-small
          dark
          @click.prevent="removeImage(image, index)"
        >
          <v-icon>{{ icons.delete }}</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiTrashCanOutline } from '@mdi/js'

export default {
  name: 'ListingGallery',

  emits: ['add', 'remove'],

  props: {
    images: {
      type: Array,
    },
  },

  data() {
    return {
      icons: {
        delete: mdiTrashCanOutline,
      },
    }
  },

  methods: {
    getImageUrl(image) {
      if (image instanceof File) {
        return URL.createObjectURL(image)
      }

      return image.url
    },

    removeImage(image, index) {
      this.$emit('remove', image, index)
    },
  },
}
</script>

<style lang="scss" scoped>
.gallery {
  height: 200px;
  width: 400px;
  overflow-y: auto;
  padding-top: 20px;

  @media (max-height: 300px) {
    height: 30%;
  }

  @media (max-width: 500px) {
    width: 30%;
  }

  &--photo {
    padding: 10px;
    position: relative;
    margin: 1px;

    &__delete-btn {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}
</style>
