export default {
  data() {
    return {
      snackbar: {
        message: '',
        show: false,
        color: 'success',
      },
    }
  },
  methods: {
    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    },
    showMainSnackbar(message, color) {
      this.$store.dispatch('showSnackbar', { message, color })
    },
  },
}
