import { render, staticRenderFns } from "./edit.vue?vue&type=template&id=0594373a&"
import script from "./edit.vue?vue&type=script&lang=js&"
export * from "./edit.vue?vue&type=script&lang=js&"
import style0 from "./edit.vue?vue&type=style&index=0&Scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VAppBar,VIcon,VPagination,VSimpleTable,VSkeletonLoader,VTab,VTabItem,VTabs,VToolbarTitle})
