<template>
  <v-autocomplete
    v-model="model"
    :items="items"
    :loading="isLoading"
    :search-input.sync="search"
    hide-no-data
    hide-selected
    item-text="text"
    item-value="id"
    label="Location"
    placeholder="Type to search place"
    :error-messages="errorMessage"
    filled
    :name="name"
  ></v-autocomplete>
</template>

<script>
import debounce from 'lodash/debounce'
import Api from '@/services/api'

export default {
  name: 'LocationField',

  props: {
    text: {
      type: String,
    },
    id: {
      type: String,
    },
    form: {
      type: Object,
    },
    errorMessage: {
      default: '',
    },
    name: {
      type: String,
      default: 'location_places_id',
    },
  },

  data() {
    return {
      search: this.text,
      model: this.id,
      isLoading: false,
      items: [
        {
          id: this.id,
          text: this.text,
        },
      ],
    }
  },

  emits: ['change'],

  methods: {
    onSelected() {
      this.$emit('change', this.locationId)
    },

    searchPlace: debounce(function () {
      if (this.search) {
        this.suggestPlace()
      }
    }, 400),

    suggestPlace() {
      Api.get('places', {
        params: { input: this.search },
      }).then((res) => {
        this.isLoading = false

        this.items = res.data.data.map((place) => this.parsePlace(place))
      })
    },

    parsePlace(place) {
      return {
        id: place.place_id,
        text: place.full_name,
      }
    },
  },

  watch: {
    search: function (value) {
      if (value) {
        this.isLoading = true
        this.searchPlace()
      } else {
        this.isLoading = false
        this.items = []
      }
    },

    model: function (value) {
      this.$emit('change', value)
    },
  },
}
</script>
